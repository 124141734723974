<template>
    <div id="promo-premium">

        <!--## Blocco tabella miei ultimi concorsi ##-->
        <div class="card">
            <div class="card-body">
                <h5 class="header-title mb-4 text-uppercase">{{$t('promoPremium.promoPremium')}}</h5>

                <template v-if="promoPremium.length > 0">
                    <div v-for="(promo, index) in promoPremium" class="row pt-3 pb-2 promo-card shadow">
                        <div class="col-9">
                            <h5>{{promo.partner}}</h5>
                            <p>
                                {{promo.descrizione}}
                            </p>
                            <div v-if="promo.code && promo.code !== ''" class="form-group col-12 col-md-8 offset-md-2">
                                <input @pointerup="handlePromoCodeClick($event, promo, index)" type="text" class="form-control form-control-sm" readonly :value="promo.code" />
                                <small v-if="lastCopiedIndex === index" class="form-text text-success text-center">{{$t('promoPremium.codiceCopiato')}}</small>
                                <small v-else class="form-text text-muted text-center">{{$t('promoPremium.cliccaPerCopiare')}}</small>
                            </div>
                        </div>
                        <div class="col-3">
                            <img class="promo-image" :src="promo.img_url" width="100%" height="auto" />
                            <div class="text-center promo-link mt-3">
                                <a target="_blank" :href="promo.site_url" class="btn btn-primary" rel="noopener">{{$t('promoPremium.vaiAlProdotto')}}</a>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="text-center">{{$t('promoPremium.nonCiSonoPromoPremium')}}</div>
                </template>

                <!-- Modal paths request error -->
                <div id="modalCallsRequestsError" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalCallsRequestsErrorLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title mt-0" id="modalCallsRequestsErrorLabel">{{$t('promoPremium.modale.errore.titolo')}}</h5>
                            </div>
                            <div id="modal-body-calls-requests-error" class="modal-body">
                                <p id="message-body-calls-requests-error"></p>
                            </div>
                            <div class="modal-footer">
                                <button type="button" id="button-ok-paths" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal" @click="$router.push({ name: routesEnum.HOME })">{{$t('generale.annulla')}}</button>
                                <button type="button" id="button-retry-paths" class="btn btn-primary waves-effect waves-light" @click="getPromoPremium()" data-dismiss="modal">{{$t('generale.riprova')}}</button>
                            </div>
                        </div><!-- /.modal-content -->
                    </div><!-- /.modal-dialog -->
                </div>
                <!-- /Modal paths request error -->

            </div>
        </div>
        <!--## Fine Blocco tabella miei ultimi concorsi ##-->

    </div>
</template>

<script>
import 'bootstrap'
import $ from 'jquery'
import {BPagination, BTable} from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import {routesEnums} from "@/helpers/enums";
import CustomUtenteApi from "@/lib/CustomConcorsandoClient/api/CustomUtenteApi";
import _ from 'lodash';
import moment from 'moment';
import { Timer } from 'easytimer.js';
import IconaBando from "@/components/IconaBando";
import {v4 as uuidv4} from 'uuid';
import {promisify} from "@/lib/utils";
import {mapGetters} from "vuex";
import {MODULES} from "@/store/modules-enum";
import {AUTH_GETTERS} from "@/store/auth/getters-enum";

const utenteClient = new CustomUtenteApi()

export default {
    name: "PromoPremium",
    components: {
        BTable,
        BPagination,
        IconaBando
    },
    data () {
        return {
            promoPremium: [],
            lastCopiedIndex: undefined,
            routesEnum: routesEnums,
            perPage: 10,
            currentPage: 1,
            dropdownAvailableSpace: "unset",
        }
    },
    computed: {
        ...mapGetters(MODULES.AUTH, [AUTH_GETTERS.PROFILE])
    },
    mounted() {
        this.isAccountPremium()
    },
    methods: {
        isAccountPremium () {
            if (this.profile.livello > 1) {
                this.$router.push({ name: routesEnums.HOME })
            } else {
                this.getPromoPremium()
            }
        },
        backToTop () {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        },
        getPromoPremium () {
            this.$emit('is-components-ready', 'calls', false)
            promisify((...args) => utenteClient.getPromoUser(...args), undefined)
                .then((res) => {
                    this.promoPremium = res;
                    this.init()
                })
                .catch((e) => {
                    console.error(e)
                    this.showModalErrorBlocker('promoPremium.modale.errore.bandi')
                })
        },
        showModalErrorBlocker (message) {
            const context = this
            context.$emit('is-components-ready', 'calls', true)
            $('#modalCallsRequestsError').modal({backdrop: 'static', keyboard: false})
            $('#message-body-calls-requests-error').html(context.$t(message))
        },
        init () {
            const context = this
            $(document).ready(() => {
                $('.dropdown-menu').on('click', function(event) {
                    event.stopPropagation();
                });

                context.$emit('is-components-ready', 'calls', true)
            })
        },
        handlePromoCodeClick (ev, promo, index) {
            console.log("Catturato evento pointerup")
            ev.target.select()
            navigator.clipboard
                .writeText(promo.code)
                .then(() => this.lastCopiedIndex = index)
                .catch((e) => {
                    console.error(e)
                })
        }
    }
}
</script>

<style scoped lang="scss">
.promo-card {

    .promo-image {

    }

    .promo-link {

    }
}
</style>
